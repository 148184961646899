<template>
 <the-layout-without-login>
        <form @submit.prevent="register" class="mt-4 mb-3">
                        <h4 class="mb-3 text-center text-default">以你的電子郵件註冊</h4>
                        <!--<h1>註冊</h1>
                        <p class="text-muted">建立帳號</p>
                        -->
                        <!--
                        <CInput placeholder="Username"
                                autocomplete="username">
                            <template #prepend-content>
                                <CIcon name="cil-user" />
                            </template>
                        </CInput>
                        -->
                        <CInput placeholder="電子郵件(必填)"
                                autocomplete="email"
                                prepend="@" v-model="username" required="required" type="email"/>
                        <CInput placeholder="密碼(必填)"
                                type="password"
                                autocomplete="new-password" v-model="password" required="required">
                            <template #prepend-content>
                                <CIcon name="cil-lock-locked" />
                            </template>
                        </CInput>
                        <CInput placeholder="密碼確認(必填)" v-model="repassword" 
                                type="password"
                                autocomplete="new-password"
                                class="mb-4" required="required">
                            <template #prepend-content>
                                <CIcon name="cil-lock-locked" />
                            </template>
                        </CInput>                        
                        <CInput placeholder="公司" v-model="company">
                            <template #prepend-content>
                                <CIcon name="cil-building" />
                            </template>
                         </CInput>  
                        <CInput placeholder="姓名" v-model="name">
                            <template #prepend-content>
                                <CIcon name="cil-user" />
                            </template>
                         </CInput>  
                        <CInput placeholder="電話" v-model="phone" >
                             <template #prepend-content>
                                <CIcon name="cil-phone" />
                            </template>
                         </CInput>  
                        <br>
                        <p class="text-center">點擊«註冊»代表您已閱讀並同意<br> <i>擷適科技</i>
                            <button type="button" class="btn btn-sm btn-link" @click="showUserAgreementModal=true">&laquo;用戶使用協定同意書條款&raquo;</button>
                           
                        </p>
                        <button type="submit" class="btn btn-success btn-lg text-white btn-block">註冊</button> 
                    </form> 
                    <p class="text-center">
                        已經擁有帳戶？  <router-link to="/login"><strong class="text-default">登入</strong></router-link> 
                    </p>         
                <CModal
                        title="用戶使用協定同意書" size="lg"
                        color="success" 
                        :show.sync="showUserAgreementModal"> 
                        <div class="text-left terms-content" v-html="userAgreementTermsContent"></div>
                        <template #footer>
                            <CButton @click="showUserAgreementModal=false" color="light">關閉</CButton> 
                        </template>
                </CModal>

                 

 </the-layout-without-login>
</template>

<script> 
import TheLayoutWithoutLogin from '../../containers/TheLayoutWithoutLogin.vue'
import dialog from '../../util/dialog';
import axios from 'axios';
import appSettings from '../../util/settings';
 

export default {   
    components: {
        TheLayoutWithoutLogin 
    },
    data() {
        return {
            username:'',
            password:'',
            repassword:'',
            name:'',
            company:'',
            phone:'',
            
            userAgreementTermsContent:"",  
            showUserAgreementModal:false,
           
            audience:''
        }
    }, 
    methods: {   
        registerResult(response){   
            var data = response.data;
            if(data.IsSuccess){
                this.$router.push({
                    path:'/login',
                    name:'Login',
                    params:{
                    }
                });

                dialog.showInforDialog("帳號尚未啟用，請先至郵箱收信認證");
            }
            else{
                dialog.showInforDialog(data.Message);
            }
        },
        register(){
            var errorMessage = '';
            if(this.username === ''){
                errorMessage += '請輸入您的 Email<br>';
            }

            if(this.password === ''){
                errorMessage += '請輸入您的密碼<br>';
            }
            else if(this.password !== this.repassword){
                errorMessage += '兩次密碼不一致<br>';
            }

            if(errorMessage === ''){
                var registerResult = this.registerResult;

                axios.post(appSettings.RegisterDb, 
                    {
                        userId : this.username,
                        password :this.password,                        
                        name:this.name,
                        company:this.company,
                        phone:this.phone,
                        audience:this.audience
                    })
                    .then(registerResult)
                    .catch(function (error) { 
                        dialog.showInforDialog(error);
                        console.log(error);
                });
            }
            else{
                 dialog.showInforDialog(errorMessage);
            }
        },
        getTerms(){ 
            var userAgreementTerms;
          

            const vm=this;
            axios.post(appSettings.RegisterTerms,{})
            .then(function(response){ 
                if(response.data !== undefined){ 
                    //console.log(response.data)
                    vm.userAgreementTermsContent = response.data; 
                }  
                //console.log(vm.serviceRuleContent); 
            })  
        }
    },
    created(){ 
        this.audience = this.$route.query.audience;
        if(this.$route.query.audience === '' || this.$route.query.audience === undefined){
            this.audience = '9F10C27C';
        }

        this.getTerms(self);  
    },
    name: 'Register'
}
</script>
